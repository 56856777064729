@each $name,$value in $w3-border-radius{
  .rounded-#{$name}{
    border-radius: $value;
  }
  .rounded-top-#{$name}{
    border-top-left-radius: $value;
    border-top-right-radius: $value;
  }
  .rounded-bottom-#{$name}{
    border-bottom-left-radius: $value;
    border-bottom-right-radius: $value;
  }
  .rounded-left-#{$name}{
    border-top-left-radius: $value;
    border-bottom-left-radius: $value;
  }
  .rounded-right-#{$name}{
    border-top-right-radius: $value;
    border-bottom-right-radius: $value;
  }
  .border-top-#{$name}{
    border-top-width: $value;
    border-top-style: solid;
  }

} 
