
.w3-box-shadow-1{
  box-shadow: 0 34px 33px -23px rgba(22, 28, 45, 0.13);
}
.w3-box-shadow-2{
  box-shadow: 0 31px 34px -20px rgba(0, 0, 0, 0.09);

}
.w3-box-shadow-3{
  box-shadow: 0 42px 44px -10px rgba(1, 23, 48, 0.12);
}
.w3-box-shadow-4{
  box-shadow: 0 32px 64px rgba(22, 28, 45, 0.08);
}
.w3-box-shadow-5{
  box-shadow: 0 62px 64px -10px rgba(1, 23, 48, 0.12);
}
.w3-image-box-shadow{
  box-shadow: 0 42px 44px -10px rgba(1, 23, 48, 0.12);
}

.w3-card-box-shadow{
  box-shadow: 0 42px 44px -10px rgba(1, 23, 48, 0.12);
}



