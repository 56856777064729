
.w3-flex-all-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

 
.w3-flex-y-center{
  display: flex;
  align-items: center;
}