@each $color, $value in $theme-colors{

  @each $op-key,$op-value in $w3-opacity{
    .w3-bg-#{$color}-opacity-#{$op-key}{
      background-color: rgba($value,$op-value);
    }
  }
  @each $op-key,$op-value in $w3-opacity{
    .w3-color-#{$color}-opacity-#{$op-key}{
      color: rgba($value,$op-value);
    }
  }
  
}
@each $op-key,$op-value in $w3-opacity{
  .w3-opacity-#{$op-key}{
    opacity:$op-value;
  }
}
.w3-text-color{
  color: var(--color-texts)!important;
}
.w3-text-color-opacity{
  color: var(--color-texts-opacity);
}
.w3-fill-color{
  fill: var(--color-texts)!important;;
}

.bg-default{
  background: var(--bg);
  &-1{
    background: var(--bg);
  }
  &-2{
    background: var(--bg-2);
  }
  &-3{
    background: var(--bg-3);
  }
  &-4{
    background: var(--bg-4);
  }
  &-5{
    background: var(--bg-5);
  }
  &-6{
    background: var(--bg-6);
  }
  &-7{
    background: var(--bg-7);
  }
  &-8{
    background: var(--bg-8);
  }
}

.w3-fill-default-4{
  fill:var(--bg-4);
}

.bg-opposite{
  background: var(--bg-opposite);
}
