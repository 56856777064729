// .h--default {
//   margin-bottom: $headings-margin-bottom;
//   font-family: $headings-font-family;
//   font-weight: $headings-font-weight;
//   // line-height: $headings-line-height;
//   color: $headings-color;
// }


// .h--default { @include font-size($h7-font-size); }
.line-height-reset{
  line-height: 1;
}
p{
  //@include font-size($text-base);
  color: $body-color;
}
.w3-text{
  // @include font-size($font-size-base);
  &-1 {
    @include font-size($display3-size);//Approx 80 px
    letter-spacing: -2.5px;
    line-height: 1.05;  //84
    &.w3-lh-reset {
      @include line-height-reset(1.15);
    }
  }
  &-2 {
    @include font-size($display4-size);//Approx 60 px
    letter-spacing: -2px;
    line-height: 1.083;  //65
    &.w3-lh-reset {
      @include line-height-reset(1.183);
    }
  }
  &-3 {
    @include font-size($h1-font-size);//Approx 48 px
    letter-spacing: -1.8px;
    line-height: 1.21;  //58
    &.w3-lh-reset {
      @include line-height-reset(1.31);
    }
  }
  &-4 {
    @include font-size($h2-font-size);//Approx 36 px
    letter-spacing: -1.2px;
    line-height: 1.3;  //48
    &.w3-lh-reset {
      @include line-height-reset(1.4);
    }
  }
  &-5 {
    @include font-size($h3-font-size);//Approx 32 px
    letter-spacing: -1.2px;
    line-height: 1.375;  //44
    &.w3-lh-reset {
      @include line-height-reset(1.475);
    }
  }
  &-6 {
    @include font-size($h4-font-size);//Approx 24 px
    letter-spacing: -0.5px;
    line-height: 1.42;  //34
    &.w3-lh-reset {
      @include line-height-reset(1.52);
    }
  }
  &-7 {
    @include font-size($h5-font-size);//Approx 21 px
    letter-spacing: -0.5px;
    line-height: 1.5;  //32
    &.w3-lh-reset {
      @include line-height-reset(1.6);
    }
  }
  &-8{
    @include font-size($p2-font-size);//Approx 19 px
    letter-spacing: -0.2px;
    line-height: 1.69;
  }
  &-9{
    @include font-size($p3-font-size);//Approx 17 px
    letter-spacing: -0.2px;
    line-height: 1.71;
  }
  &-10{
    @include font-size($p4-font-size); //for browser default assuming 16px
  }
  &-11{
    @include font-size($p5-font-size);//Approx 15 px
    letter-spacing: -0.1px;
    line-height: 1.73;
  }
  &-12 {
    @include font-size($h8-font-size);//Approx 13 px
    line-height: 1.63;  
    &.w3-lh-reset {
      @include line-height-reset(1.73);
    }
  }
  &-13 {
    font-size: $font-size-base * 0.625; //Approx 10 px
    line-height: 1.63;  
  }
  &-14 {
    font-size: $font-size-base * 0.5; //Approx 8 px
    line-height: 1.63;
  }
}

.w3-text-underline {
  text-decoration: underline;
}


.text-linethrough{
  text-decoration: line-through;
}